import * as React from 'react';
import aboutUsImage from "./../images/about.webp";

function AboutUs(props) {
    return (
            <section class="flex flex-col lg:flex-row items-start bg-main-green">
                <article class="bg-main-green text-main-gold mb-5 w-full lg:w-auto">
                    <header>
                        <h2
                            class="text-center font-[Italianno] text-5xl lg:text-5xl py-4 lg:py-7"
                        >
                            Sobre Nosotros
                        </h2>
                    </header>
                    <p class="text-left font-[google-italiana] px-4 lg:px-12 text-sm lg:text-lg">
                        Osteria Pastai nace una tarde de vino entre dos amigos inspirados
                        por la pasión a la cocina italiana, juntos decidieron traer un
                        pedacito de Italia a tu mesa.
                        <br /><br />
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et mi
                        nec nisl ultricies lacinia. Nullam nec nunc nec nisl ultricies
                        <br /><br/>
                        ¡Ven y disfruta de Osteria Pastai, un pedacito de Italia en Honduras!
                        <br /><br/>
                    </p>
                </article>
                <div class="w-full lg:w-[1600px]">
                    <img
                        class="object-cover object-center opacity-85 w-full h-auto"
                        src={aboutUsImage}
                        alt="Imagen Sobre Nosotros"
                    />
                </div>
           
            </section>        
    );
}

export default AboutUs;