function BooksWeekCard({day, number, reservations, guests}) {

    return (
        <div class="py-6 border-2 rounded-3xl border-main-green hover:scale-110 transition-transform">
            <h2 class="text-2xl text-center pb-2 border-b-2 border-main-green">{day} {number}</h2>
            <div class="flex flex-col pt-4 text-center ">
                <h3 class="text-lg text-main-green font-bold">{reservations ? reservations : '0'} Reservas</h3>
                <h4 class="text-lg text-main-green font-bold">{guests ? guests : '0'} Personas</h4>
            </div>
        </div >
    );
}

export default BooksWeekCard;