import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Logo from './../images/logo.svg'

const drawerWidth = 200;
const navItems = [
  { name: 'Sobre nosotros', id: 'aboutus' },
  { name: 'Galería', id: 'food' },
  { name: 'Eventos', id: 'events' },
  { name: 'Contacto', id: 'contact' },
];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };


  const [bgHeader, setBgHeader] = React.useState('#00000060');

  React.useEffect(() => {
    const handleScroll = () => {
      if (document.documentElement.scrollTop > document.getElementById("aboutus").offsetTop - 100) {
        setBgHeader(props.theme.palette.primary.main);
      } else {
        setBgHeader("#00000060"); 
      }
    };

    document.addEventListener("scroll", handleScroll);

    // Limpia el event listener cuando el componente se desmonte
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [props.theme.palette.primary.main]);

  const drawer = (
    <Box class="bg-main-green rounded" onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <a href="/">
        <img src={Logo} class="border-b-4 border-main-gold" alt="Logo Osteria" />
      </a>
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <Link
              key={item.name}
              to={item.id}
              smooth={true}
              offset={-65}
              duration={500}
            >
              <ListItemButton
                onClick={handleDrawerToggle}
                class="text-main-gold font-[google-italiana]
                font-medium px-10 py-2.5 text-center
                inline-flex items-center"
                sx={{ textAlign: 'center' }}>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
        <RouterLink to="/menu">
          <ListItemButton
            class="text-main-gold font-[google-italiana]
                font-medium px-10 py-2.5 text-center
                inline-flex items-center"
            sx={{ textAlign: 'center' }}>
            <ListItemText primary="Menu" />
          </ListItemButton>
        </RouterLink>
        <RouterLink to="/booking">
          <ListItemButton
            class="text-main-gold font-[google-italiana]
                font-medium px-10 py-2.5 text-center
                inline-flex items-center"
            sx={{ textAlign: 'center' }}>
            <ListItemText primary="Reservas" />
          </ListItemButton>
        </RouterLink>
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav" sx={{
        backgroundColor: bgHeader, transitionProperty: "all",
        transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
        transitionDuration: "150ms"
      }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, color: props.theme.palette.secondary.main }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            <a href='/'>
              <img src={Logo} alt="Logo Osteria" class="w-20" />
            </a>
          </Typography>
          <Box sx={{ borderBottom: 4, borderBottomColor: props.theme.palette.secondary.main, display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Link
                key={item.name}
                to={item.id}
                smooth={true}
                offset={-65}
                duration={500}
              >
                <Button
                  class="
                  text-main-gold font-[google-italiana] cursor-pointer
                  hover:bg-main-gold
                  hover:text-main-green
                  font-medium px-2.5 py-2.5
                  transition-all duration-200 ease-in-out scale-100 opacity-90 hover:opacity-100"
                  sx={{ color: props.theme.palette.secondary.main }}>
                  {item.name}
                </Button>
              </Link>
            ))}
            <RouterLink to="/menu">
              <Button
                class="
                text-main-gold font-[google-italiana] cursor-pointer
                hover:bg-main-gold
                hover:text-main-green
                font-medium px-2.5 py-2.5
                transition-all duration-200 ease-in-out scale-100 opacity-90 hover:opacity-100"
                sx={{ color: props.theme.palette.secondary.main }}>
                Menu
              </Button>
            </RouterLink>
            <RouterLink to="/booking">
              <Button
                class="
                text-main-gold font-[google-italiana] cursor-pointer
                hover:bg-main-gold
                hover:text-main-green
                font-medium px-2.5 py-2.5
                transition-all duration-200 ease-in-out scale-100 opacity-90 hover:opacity-100"
                sx={{ color: props.theme.palette.secondary.main }}>
                Reservas
              </Button>
            </RouterLink>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: props.theme.palette.primary.main },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}


export default DrawerAppBar;