import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import { Link as RouterLink} from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Logo from './../images/logo.svg'

function Menu2MainNavBar(props) {
    return(   
        <AppBar component="nav" sx={{ backgroundColor: props.theme.palette.primary.main }}>
            <Toolbar>
    
            <Typography
                component="div"
                sx={{ flexGrow: 1, display: 'block' }}
            >
                <a href='/'>
                    <img src={Logo} alt="Logo Osteria" class="w-20" />
                </a>
            </Typography>
            <Box sx={{ borderBottom: 4, borderBottomColor: props.theme.palette.secondary.main, display: 'block' }}> 
                <RouterLink to="/booking">
                    <Button 
                        class="
                        text-main-gold font-[google-italiana] cursor-pointer
                        hover:bg-main-gold
                        hover:text-main-green
                        font-medium px-2 py-2.5
                        transition-all duration-200 ease-in-out scale-100 opacity-90 hover:opacity-100" 
                        sx={{ color: props.theme.palette.secondary.main }}>
                        Reserva tu mesa
                    </Button>
                </RouterLink>
            </Box>
            </Toolbar>
        </AppBar>
    );
}

export default Menu2MainNavBar;