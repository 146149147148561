import * as React from 'react';
import coverImage0 from "./../images/coverImage1.png";
import coverImage1 from "./../images/coverImage2.png";
import coverImage2 from "./../images/coverImage3.png";
import coverImage3 from "./../images/coverImage4.png";
import coverImage4 from "./../images/coverImage5.png";
import coverImage5 from "./../images/coverImage6.png";
import mobileImage0 from "./../images/mobileCover1.png";
import mobileImage1 from "./../images/mobileCover2.png";
import mobileImage2 from "./../images/mobileCover3.png";
import mobileImage3 from "./../images/mobileCover4.png";
import mobileImage4 from "./../images/mobileCover5.png";
import mobileImage5 from "./../images/mobileCover6.png";
import osteriaLogo from "./../images/logo-osteria-improved.png";
import { isMobile } from 'react-device-detect';
import '../styles/slideshow.css';

function Cover(props) {
    const imagesToShow = 
        props.covertype.event && !isMobile ? [coverImage3, coverImage4, coverImage5] :
        props.covertype.event && isMobile ? [mobileImage3, mobileImage4, mobileImage5] :
        !props.covertype.event && !isMobile ? [coverImage0, coverImage1, coverImage2] :
        !props.covertype.event && isMobile ? [mobileImage0, mobileImage1, mobileImage2] : null;
        
    return (
        <div class="slideshow-container">
            <div class="w-full slideshow bg-black">
                <img src={imagesToShow[0]} class="object-cover object-center w-full h-auto" alt="Foto Portada" />
                <img src={imagesToShow[1]} class="object-cover object-center w-full h-auto" alt="Foto Portada" />
                <img src={imagesToShow[2]} class="object-cover object-center w-full h-auto" alt="Foto Portada" />
            </div> 
            <div class="w-full absolute top-[200px] lg:top-[100px] flex-col flex items-center justify-center">
                <div class="flex flex-col justify-center items-center pt-14 pb-10 px-8 ">
                    <img class="w-[100%] lg:w-[45rem] opacity-100" src={osteriaLogo} alt="Osteria Pastai" />
                    <button className={props.covertype.event ? `w-80 lg:w-[70%] border-4 text-xs lg:text-xl font-[bagiste]
                                 border-main-green bg-main-gold text-main-green font-bold pt-4 pb-2 mt-14  lg:mt-8 rounded-full
                                  hover:brightness-110` : `w-56 lg:w-[55%] border-4 text-xs lg:text-xl font-[bagiste]
                                 border-main-green bg-main-gold text-main-green font-bold pt-4 pb-2 mt-14  lg:mt-8 rounded-full
                                  hover:brightness-110`}>
                        <a class="" href="/booking">{props.covertype.text}</a>
                    </button>
                </div>
            </div>

        </div>
    );
}

export default Cover;