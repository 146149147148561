import * as React from 'react';
import drink1 from './../images/drink1.jpg';
import drink2 from './../images/drink2.jpg';
import drink3 from './../images/drink3.jpg';
import drink4 from './../images/drink4.jpg';

function Drinks (props) {
    return (
        <section class="bg-main-green text-main-gold w-full lg:w-auto">
            <div>
                <header>
                    <h1
                        class="text-center font-[Italianno] text-5xl lg:text-5xl py-4 lg:py-7"
                    >
                        Nuestras Bebidas
                    </h1>
                </header>
            </div>
            <div class="flex flex-col grid grid-cols-2 md:grid-cols-4 gap-0">
                <div class="relative overflow-hidden">
                    <img src={drink1} alt="drink1" class="w-full h-full"/>
                </div>
                <div class="relative overflow-hidden">
                    <img src={drink2} alt="drink2" class="w-full h-full"/>
                </div>
                <div class="relative overflow-hidden">
                    <img src={drink3} alt="drink3" class="w-full h-full"/>
                </div>
                <div class="relative overflow-hidden">
                    <img src={drink4} alt="drink4" class="w-full h-full"/>
                </div>
            </div>

        </section>
    );
}


export default Drinks;