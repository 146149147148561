import logoOsteria from '../images/logo-osteriapastai.png';


function Footer() {
    return (
        <footer class="flex flex-col lg:flex-row
                       border-t-2 border-main-gold border-opacity-55
                     bg-main-green justify-between">
            <div class="flex mt-4 sm:mt-0 items-center justify-center px-12 py-2">
                <img class="w-20 lg:w-24" src={logoOsteria} alt='Osteria Pastai'></img>
            </div>
            <div class="flex items-center justify-center 
                        px-12  mb-4 sm:mb-0 pb-2 lg:pb-0
                        text-sm lg:text-base font-[google-italianno] text-main-gold">
                <p class="">&copy; Powered by </p>
                <a class="font-bold hover:brightness-110" href='https://solarkey.pages.dev'>&nbsp;Solar Key Solutions</a>
            </div>
        </footer>
    );
}

export default Footer;