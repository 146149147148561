import React, { useEffect, useState } from 'react';
import './../styles/Menu.css';
import MenuFilterButton from './MenuFilterButton';
import ButtonUp from './ButtonUp';

const data = [
  {
    "id": 1,
    "name": "Zuppa Di Piselli",
    "category": "Antipasti",
    "description": "Sopa de chícharos, prosciutto crujiente, menta fresca",
    "price": "L 195.00"
  },
  {
    "id": 2,
    "name": "Polpo Alla Grilia",
    "category": "Antipasti",
    "description": "Ensalada de papa, chimol alla pastai",
    "price": "L 325.00"
  },
  {
    "id": 3,
    "name": "Insalata Italiana",
    "category": "Antipasti",
    "description": "Lechuga, radicchio, garbanzos, Genova salami, gorgonzola, vinagreta de vino tinto",
    "price": "L 265.00"
  },
  {
    "id": 4,
    "name": "Insalata di Mare",
    "category": "Antipasti",
    "description": "Camarones, calamares, papa, zanahoria, apio, vinagreta de limón",
    "price": "L 365.00"
  },
  {
    "id": 5,
    "name": "Bruscheta",
    "category": "Antipasti",
    "description": "Pan brioche, tomate cherry rostizado, queso feta, aceite de oliva, albahaca",
    "price": "L 215.00"
  },
  {
    "id": 6,
    "name": "Polpette di Pollo",
    "category": "Antipasti",
    "description": "Albóndigas de pollo, Peperonata, aceite de albahaca",
    "price": "L 285.00"
  },
  {
    "id": 7,
    "name": "Arancini",
    "category": "Antipasti",
    "description": "Bolitas de risotto frito, relleno con ‘Nduja (salchicha Italiana picante), salsa pomodoro",
    "price": "L 315.00"
  },
  {
    "id": 8,
    "name": "Tabla Italiana",
    "category": "Antipasti",
    "description": "Pan focaccia, prosciutto, mortadella, salami, queso provolone, aceitunas",
    "price": "2 personas L 390.00",
    "price1": "4 personas L 780.00"
  },
  {
    "id": 9,
    "name": "Pappardelle",
    "category": "Primi Piatti",
    "description": "Pechuga de pollo, salsa Alfredo, parmesano",
    "price": "L 355.00"
  },
  {
    "id": 10,
    "name": "Orecchiette",
    "category": "Primi Piatti",
    "description": "Pechuga de pollo, salsa Alfredo, parmesano",
    "price": "L 355.00"
  },
  {
    "id": 11,
    "name": "Bucatini Cacio e Pepe",
    "category": "Primi Piatti",
    "description": "Pimienta negra, queso pecorino",
    "price": "L 325.00"
  },
  {
    "id": 12,
    "name": "Risotto Ai Funghi e Tartufo",
    "category": "Primi Piatti",
    "description": "Arroz arborio, hongos, aceite de trufa, queso parmesano",
    "price": "L 445.00"
  },
  {
    "id": 13,
    "name": "Agnolotti",
    "category": "Primi Piatti",
    "description": "Relleno con queso ricotta y espinaca, nueces, salvia, salsa de mantequilla caramelizada",
    "price": "L 345.00"
  },
  {
    "id": 14,
    "name": "Fetuccine Al Limone",
    "category": "Primi Piatti",
    "description": "Limón, albahaca fresca, parmesano",
    "price": "L 315.00"
  },
  {
    "id": 15,
    "name": "Tonnarelli Bianco e Nero",
    "category": "Primi Piatti",
    "description": "Camarones, hongos, tocino crujiente, salsa blanca con ajo y parmesano",
    "price": "L 485.00"
  },
  {
    "id": 16,
    "name": "Ravioli Bolognese",
    "category": "Primi Piatti",
    "description": "Relleno con carne, salsa pomodoro, parmesano",
    "price": "L 345.00"
  },
  {
    "id": 17,
    "name": "Gnocchetti Sardi",
    "category": "Primi Piatti",
    "description": "Asado de tira, salsa pomodoro",
    "price": "L 395.00"
  },
  {
    "id": 18,
    "name": "Filetto di Robalo",
    "category": "Secondi Piatti",
    "description": "Chícharos, papa, tocino, salsa de eneldo",
    "price": "L 435.00"
  },
  {
    "id": 19,
    "name": "Filetto di Salmone alla Toscana",
    "category": "Secondi Piatti",
    "description": "Tomate cherry, espinaca, pure de papa, salsa blanca",
    "price": "L 675.00"
  },
  {
    "id": 20,
    "name": "Filetto di Branzino",
    "category": "Secondi Piatti",
    "description": "Risotto de verduras, parmesano",
    "price": "L 415.00"
  },
  {
    "id": 21,
    "name": "Pollo Parmigiana",
    "category": "Secondi Piatti",
    "description": "Pechuga de pollo, mozzarella, pasta con salsa pomodoro",
    "price": "L 415.00"
  },
  {
    "id": 22,
    "name": "Cotoletta Alla Milanese",
    "category": "Secondi Piatti",
    "description": "Filete de cerdo empanizado, pure de camote, ensalada mixta",
    "price": "L 385.00"
  },
  {
    "id": 23,
    "name": "Filetto Mignon",
    "category": "Secondi Piatti",
    "description": "Pure de papa, puerro, hongos, salsa demi-glace",
    "price": "L 645.00"
  },
  {
    "id": 24,
    "name": "Bistecca Rib-Eye",
    "category": "Secondi Piatti",
    "description": "Baby papas, salsa verde",
    "price": "L 855.00"
  },
  {
    "id": 25,
    "name": "Tiramisú",
    "category": "Dolci",
    "description": "",
    "price": "L 185.00"
  },
  {
    "id": 26,
    "name": "Semifreddo al Limone",
    "category": "Dolci",
    "description": "",
    "price": "L 165.00"
  },
  {
    "id": 27,
    "name": "Budino Al Cioccolato",
    "category": "Dolci",
    "description": "",
    "price": "L 185.00"
  },
  {
    "id": 28,
    "name": "Torta di Mele",
    "category": "Dolci",
    "description": "",
    "price": "L 175.00"
  },
  {
    "id": 29,
    "name": "Spumoni",
    "category": "Dolci",
    "description": "Trio de gelatos, pistacho, vainilla, chocolate",
    "price": "L 195.00"
  },
  {
    "id": 30,
    "name": "Tenuta San Giorgio",
    "category": "Vinos Espumosos",
    "description": "Brut rose, venet o Italia VN",
    "price": "L 775.00"
  },
  {
    "id": 31,
    "name": "Perlino Proseco",
    "category": "Vinos Espumosos",
    "description": "Veneto, Italia NV",
    "price": "L 865.00",
  },
  {
    "id": 32,
    "name": "Luca Bosio Tropical",
    "category": "Vinos Espumosos",
    "description": "Blanco, Frizzante, Semi Dulce",
    "price": "Copa: L 145.00",
    "price1": "Botella: L 725.00"
  },
  {
    "id": 33,
    "name": "Luca Bosio",
    "category": "Vinos Espumosos",
    "description": "Tinto, Frizante, Semi dulce",
    "price": "Copa: L 145.00",
    "price1": "Botella: L 725.00"
  },
  {
    "id": 34,
    "name": "Zonin",
    "category": "Vinos Espumosos",
    "description": "Prosecco doc, brut, italia",
    "price": "Copa: L 165.00",
    "price1": "Botella: L 825.00"
  },
  {
    "id": 35,
    "name": "Marramifro Brut",
    "category": "Vinos Espumosos",
    "description": "(Método tradicional) Abruzzo, Italia NV",
    "price": "L 1750.00"
  },
  {
    "id": 36,
    "name": "Bertoldi \"Flor di Vite\"",
    "category": "Vinos Espumosos",
    "description": "Moscato, Veneto, Italia",
    "price": "L 790.00"
  },
  {
    "id": 37,
    "name": "Altanza",
    "category": "Vinos Blancos",
    "description": "Sauvignon blanc, Rioja, España 2021",
    "price": "L 875.00"
  },
  {
    "id": 38,
    "name": "Lagar de Cervera",
    "category": "Vinos Blancos",
    "description": "Albariño, Rias Baixas, España 2021",
    "price": "L 1090.00"
  },
  {
    "id": 39,
    "name": "Principi di Butera",
    "category": "Vinos Blancos",
    "description": "Chardonnay, Sicilia, Italia 2021",
    "price": "L 950.00"
  },
  {
    "id": 40,
    "name": "Principi di Butera",
    "category": "Vinos Blancos",
    "description": "Carizza, Inolia, Sicilia, Italia 2019",
    "price": "L 990.00"
  },
  {
    "id": 41,
    "name": "Marramifro \"Anima\"",
    "category": "Vinos Blancos",
    "description": "Trebbiano, Abruzzo, Italia 2022",
    "price": "L 990.00"
  },
  {
    "id": 42,
    "name": "Bertoldi",
    "category": "Vinos Blancos",
    "description": "Pinot Grigio, Veneto, Italia 2022",
    "price": "Copa: L 165.00",
    "price1": "Botella: L 825.00"
  },
  {
    "id": 43,
    "name": "Belcotte",
    "category": "Vinos Blancos",
    "description": "Arneis, Piemonte, Italia 2022",
    "price": "L 990.00"
  },
  {
    "id": 44,
    "name": "Tassajara",
    "category": "Vinos Tintos Estados Unidos",
    "description": "Pinot Noir, Monterey, California 2019",
    "price": "L 990.00"
  },
  {
    "id": 44,
    "name": "La Vielle Fermi",
    "category": "Vinos Tintos Francia",
    "description": "Garnacha, Romano Sur, Francia 2020",
    "price": "L 760.00"
  },
  {
    "id": 45,
    "name": "Zonin Zurya",
    "category": "Vinos Tintos Italia",
    "description": "Primitivo, Puglia, Italia",
    "price": "L 860.00"
  },
  {
    "id": 46,
    "name": "Principi di Butera \"Amira\"",
    "category": "Vinos Tintos Italia",
    "description": "Nero d'Avola, Sicilia, 2020",
    "price": "L 990.00"
  },
  {
    "id": 47,
    "name": "Banfi \"Stilnovo\"",
    "category": "Vinos Tintos Italia",
    "description": "Sangiovese, Toscana, 2021",
    "price": "L 1250.00"
  },
  {
    "id": 48,
    "name": "Guidobono \"Due Gelsi\"",
    "category": "Vinos Tintos Italia",
    "description": "Barbera, Piamonte, 2019",
    "price": "L 1440.00"
  },
  {
    "id": 49,
    "name": "Guidobono",
    "category": "Vinos Tintos Italia",
    "description": "Barolo, Nebbiolo, Piamonte, 2019",
    "price": "L 2350.00"
  },
  {
    "id": 50,
    "name": "Belcotte",
    "category": "Vinos Tintos Italia",
    "description": "Pinot nero, Piemonte 2022",
    "price": "L 1150.00"
  },
  {
    "id": 51,
    "name": "Belcotte \"Nuwanda\"",
    "category": "Vinos Tintos Italia",
    "description": "Barbera d'asti, Piemonte 2021",
    "price": "L 1350.00"
  },
  {
    "id": 52,
    "name": "Belcotte",
    "category": "Vinos Tintos Italia",
    "description": "Barolo bussia, Nebbiolo, Piamonte 2019",
    "price": "L 2590.00"
  },
  {
    "id": 53,
    "name": "I Casali",
    "category": "Vinos Tintos Italia",
    "description": "Montepulciano, Abruzzo, 2022",
    "price": "Copa: L 165.00",
    "price1": "Botella: L 825.00"
  },
  {
    "id": 54,
    "name": "Marramiero \"Dama\"",
    "category": "Vinos Tintos Italia",
    "description": "Montepulciano, Abruzzo, 2021",
    "price": "L 990.00"
  },
  {
    "id": 55,
    "name": "Marramiero \"Inferi\" Reserva",
    "category": "Vinos Tintos Italia",
    "description": "Montepulciano, Abruzzo, 2019",
    "price": "L 1970.00"
  },
  {
    "id": 56,
    "name": "Bertoldi Valpolicella",
    "category": "Vinos Tintos Italia",
    "description": "Corvina/Rodinella, Veneto 2018",
    "price": "L 1250.00"
  },
  {
    "id": 57,
    "name": "Bertoldi Amore Reserva",
    "category": "Vinos Tintos Italia",
    "description": "Corvina/Rodinella, Veneto 2016",
    "price": "L 2590.00"
  },
  {
    "id": 58,
    "name": "Altanza",
    "category": "Vinos Tintos España",
    "description": "Capitoso, Tempranillo, Ribera del duero, 2020",
    "price": "L 875.00"
  },
  {
    "id": 59,
    "name": "Reto",
    "category": "Vinos Tintos España",
    "description": "Tempranillo, Ribera del duero, 2019",
    "price": "L 825.00"
  },
  {
    "id": 60,
    "name": "Finca San Martín",
    "category": "Vinos Tintos España",
    "description": "Tempranillo, Rioja, 2019",
    "price": "L 990.00"
  },
  {
    "id": 61,
    "name": "Viña Arana",
    "category": "Vinos Tintos España",
    "description": "Tempranillo, Gran Reserva, Rioja, 2012",
    "price": "L 1600.00"
  },
  {
    "id": 62,
    "name": "Aster",
    "category": "Vinos Tintos España",
    "description": "Tempranillo, Ribera del Duero, 2019",
    "price": "L 1750.00"
  },
  {
    "id": 63,
    "name": "Petalos Palacio",
    "category": "Vinos Tintos España",
    "description": "Mencia, Bierzo, 2021",
    "price": "L 1250.00"
  },
  {
    "id": 64,
    "name": "La Montesa",
    "category": "Vinos Tintos España",
    "description": "Garnacha, Rioja, 2019",
    "price": "L 1250.00"
  },
  {
    "id": 65,
    "name": "Osteria Pastai Martini",
    "category": "Mixology",
    "description": "Vodka, prosecco, sweet and sour de la casa",
    "price": "L 200.00"
  },
  {
    "id": 66,
    "name": "Negroni",
    "category": "Mixology",
    "description": "Gin, sweet vermouth, campari",
    "price": "L 180.00"
  },
  {
    "id": 67,
    "name": "Boulevardier",
    "category": "Mixology",
    "description": "Campari, gin, bourbon",
    "price": "L 180.00"
  },
  {
    "id": 68,
    "name": "Mezcal Negroni",
    "category": "Mixology",
    "description": "Campari, gin, mezcal",
    "price": "L 200.00"
  },
  {
    "id": 69,
    "name": "Cosmopolitan",
    "category": "Mixology",
    "description": "Vodka, Triple sec, cranberry juice, lime",
    "price": "L 190.00"
  },
  {
    "id": 70,
    "name": "Italian Manhattan",
    "category": "Mixology",
    "description": "Whisky, Sweet vermouth, amaro",
    "price": "L 180.00"
  },
  {
    "id": 71,
    "name": "Aperol Spritz",
    "category": "Mixology",
    "description": "Proseco, aperol",
    "price": "L 190.00"
  },
  {
    "id": 72,
    "name": "Amalfi Lemon Drop",
    "category": "Mixology",
    "description": "Vodka, Limoncello, sour mix",
    "price": "L  210.00"
  },
  {
    "id": 73,
    "name": "Moscow Mule",
    "category": "Mixology",
    "description": "Vodka, Ginger beer, Rosemary lime",
    "price": "L 200.00"
  },
  {
    "id": 74,
    "name": "Classic Gin Tonic",
    "category": "Mixology",
    "description": "Gin, Tonic water",
    "price": "L 190.00"
  },
  {
    "id": 75,
    "name": "Premium Gin Tonic",
    "category": "Mixology",
    "description": "Gin, Tonic Water, Fruta de temporada",
    "price": "L 250.00"
  },
  {
    "id": 76,
    "name": "Carajillo",
    "category": "Mixology",
    "description": "Liqueur 43, espresso",
    "price": "L 190.00"
  },
  {
    "id": 77,
    "name": "Bellini",
    "category": "Mixology",
    "description": "Prosecco, Peach puree",
    "price": "L 160.00"
  },
  {
    "id": 78,
    "name": "Morire Sognando",
    "category": "Mixology",
    "description": "Vodka, Coconut cream de la casa, lime",
    "price": "L 200.00"
  },
  {
    "id": 79,
    "name": "Virgin Alessandrina",
    "category": "Mixology",
    "description": "Soda rosa, ginger beer, peac puree",
    "price": "L 130.00"
  },
  {
    "id": 80,
    "name": "Amaretto",
    "category": "Liquors",
    "description": "",
    "price": "L 200.00"
  },
  {
    "id": 81,
    "name": "Grappa Primitivo",
    "category": "Liquors",
    "description": "",
    "price": "L 150.00"
  },
  {
    "id": 82,
    "name": "Limoncello",
    "category": "Liquors",
    "description": "",
    "price": "L 120.00"
  },
  {
    "id": 83,
    "name": "Grand Marnifr",
    "category": "Liquors",
    "description": "",
    "price": "L 180.00"
  },
  {
    "id": 84,
    "name": "Amaro",
    "category": "Liquors",
    "description": "",
    "price": "L 160.00"
  },
  {
    "id": 85,
    "name": "Cognac ST.Remy",
    "category": "Liquors",
    "description": "",
    "price": "L 200.00"
  },
  {
    "id": 86,
    "name": "Verolis Cacao Cold Brew",
    "category": "Liquors",
    "description": "",
    "price": "L 120.00"
  },
  {
    "id": 87,
    "name": "Sambuca",
    "category": "Liquors",
    "description": "",
    "price": "L 200.00"
  },
  {
    "id": 88,
    "name": "Tequila 1800",
    "category": "Liquors",
    "description": "",
    "price": "L 190.00"
  },
  {
    "id": 89,
    "name": "Mezcal Nuku",
    "category": "Liquors",
    "description": "",
    "price": "L 145.00"
  },
  {
    "id": 90,
    "name": "Vodka Grey Goose",
    "category": "Liquors",
    "description": "",
    "price": "L 180.00"
  },
  {
    "id": 91,
    "name": "Vodka Absolut",
    "category": "Liquors",
    "description": "",
    "price": "L 120.00"
  },
  {
    "id": 92,
    "name": "Whisky Black Label",
    "category": "Liquors",
    "description": "",
    "price": "L 120.00"
  },
  {
    "id": 93,
    "name": "Whisky The Gladstone",
    "category": "Liquors",
    "description": "",
    "price": "L 160.00"
  },
  {
    "id": 94,
    "name": "Gin Nordes",
    "category": "Liquors",
    "description": "",
    "price": "L 160.00"
  },
  {
    "id": 95,
    "name": "Gin Tanqueray",
    "category": "Liquors",
    "description": "",
    "price": "L 120.00"
  },
  {
    "id": 96,
    "name": "Cervezas Nacionales",
    "category": "Liquors",
    "description": "",
    "price": "L 75.00"
  },
  {
    "id": 97,
    "name": "Cervezas Importadas",
    "category": "Liquors",
    "description": "Limonada fresca de la casa",
    "price": "L 90.00"
  },
  {
    "id": 98,
    "name": "Peroni",
    "category": "Liquors",
    "description": "Jugo de naranja fresco de la casa",
    "price": "L 95.00"
  },
  {
    "id": 99,
    "name": "Limonada",
    "category": "Non-Alcoholic Beverages",
    "description": "",
    "price": "L 50.00"
  },
  {
    "id": 100,
    "name": "Limonada con menta",
    "category": "Non-Alcoholic Beverages",
    "description": "",
    "price": "L 75.00"
  },
  {
    "id": 101,
    "name": "Té Frío de la casa",
    "category": "Non-Alcoholic Beverages",
    "description": "",
    "price": "L 50.00"
  },
  {
    "id": 102,
    "name": "Coca Cola",
    "category": "Non-Alcoholic Beverages",
    "description": "",
    "price": "L 60.00"
  },
  {
    "id": 103,
    "name": "Sprite",
    "category": "Non-Alcoholic Beverages",
    "description": "",
    "price": "L 60.00"
  },
  {
    "id": 104,
    "name": "J. Gasco Aperitivo Bitter",
    "category": "Non-Alcoholic Beverages",
    "description": "",
    "price": "L 90.00"
  },
  {
    "id": 105,
    "name": "J.Gasco Ginger Beer",
    "category": "Non-Alcoholic Beverages",
    "description": "",
    "price": "L 90.00"
  },
  {
    "id": 106,
    "name": "J. Gasco Soda Rosa",
    "category": "Non-Alcoholic Beverages",
    "description": "",
    "price": "L 90.00"
  },
  {
    "id": 107,
    "name": "Sanpellegrino",
    "category": "Non-Alcoholic Beverages",
    "description": "",
    "price": "L 90.00"
  },
  {
    "id": 108,
    "name": "Aqua Panna Natural",
    "category": "Non-Alcoholic Beverages",
    "description": "",
    "price": "L 90.00"
  },
  {
    "id": 109,
    "name": "Expresso",
    "category": "Non-Alcoholic Beverages",
    "description": "",
    "price": "L 40.00"
  },
  {
    "id": 110,
    "name": "Americano",
    "category": "Non-Alcoholic Beverages",
    "description": "",
    "price": "L 60.00"
  },
];

const Menu = (props) => {
  const [foods, setFoods] = useState(data);
  const [transitioning, setTransitioning] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('All');

  const categories = foods.reduce((acc, item) => {
    if (!acc[item.category]) {
      acc[item.category] = [];
    }
    acc[item.category].push(item);
    return acc;
  }, {});

  const [filters, setFilters] = useState([
    { title: "All", active: true, category: null },
    { title: "Antipasti", active: false, category: "Antipasti" },
    { title: "Primi Piatti", active: false, category: "Primi Piatti" },
    { title: "Secondi Piatti", active: false, category: "Secondi Piatti" },
    { title: "Dolci", active: false, category: "Dolci" },
    { title: "Vinos Espumosos", active: false, category: "Vinos Espumosos" },
    { title: "Vinos Blancos", active: false, category: "Vinos Blancos" },
    { title: "Vinos Tintos", active: false, category: "Vinos Tintos" },
    { title: "Mixology", active: false, category: "Mixology" },
    { title: "Liquors", active: false, category: "Liquors" },
    { title: "Non-Alcoholic", active: false, category: "Non-Alcoholic" },
  ]);

  //   Filter Type burgers/pizza/etc
  const filterType = (category) => {
    setTransitioning(true);
    setFadeIn(false);

    const updatedFilters = filters.map((filter) => ({
      ...filter,
      active: filter.category === category,
    }));

    setFilters(updatedFilters);

    setTimeout(() => {
      if (category !== null) {
        setFoods(data.filter((item) => item.category.startsWith(category)));
      } else {
        setFoods(data);
      }
      setTransitioning(false);
      setFadeIn(true);
    }, 500);
  };

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  const handleFilterChange = (event) => {
    const selectedCategory = event.target.value;
    if (selectedCategory === 'All') {
      setSelectedFilter(null);
      filterType(null);
    } else {
      setSelectedFilter(selectedCategory);
      filterType(selectedCategory);
    }
  };

  return (
    <div className='w-full h-[100%] bg-[#f3f0e2]'>
      <div className='mt-10 max-w-[1640px] m-auto px-4 pt-12'>
        <div className='flex lg:flex-row justify-center'>
          <div className='hidden lg:flex justify-center justfiy-between flex-wrap'>
            {filters.map((filter, index) => (
              <MenuFilterButton
                key={index}
                onClick={() => { filterType(filter.category) }}
                title={filter.title}
                active={filter.active}
              />
            ))}
          </div>

          <div className='lg:hidden flex justify-start items-start w-full px-20 pt-6'>
            <select
              defaultValue='All'
              value={selectedFilter}
              onChange={handleFilterChange}
              className='w-full p-2 font-[google-italianno] border-2 border-black rounded-md bg-[#f3f0e2]'
            >
              {filters.map((filter, index) => (
                <option key={index} value={filter.category}>
                  {filter.title}
                </option>
              ))}
            </select>
          </div>
        </div>

        {Object.keys(categories).map((category) => (
          <div key={category}>
            <h1 className={`text-3xl text-center font-[bagiste] font-extrabold mt-10 mb-8 ${transitioning ? 'fade-out' : fadeIn ? 'fade-in' : ''}`}>{category}</h1>
            <hr className={`border-t-2 my-4 ${transitioning ? 'fade-out' : fadeIn ? 'fade-in' : ''}`} />
            <div className='grid grid-cols-2 place-items-center gap-6'>
              {categories[category].map((item) => (
                <div
                  key={item.id}
                  className={`border h-full w-full sm:w-[75%] shadow-lg rounded-lg ${transitioning ? 'fade-out' : fadeIn ? 'fade-in' : ''}`}
                >
                  <div className='sm:flex justify-between px-2 py-4'>
                    <h2 className='font-extrabold text-xl font-[bagiste]'>{item.name}</h2>
                    <p>
                      <span className='bg-orange-500 text-white p-1 text-sm rounded-full'>
                        {item.price}
                      </span>
                      <br />
                      <span className='bg-orange-500 text-white p-1 text-sm rounded-full'>
                        {item.price1}
                      </span>
                    </p>
                  </div>
                  <p className='px-2 font-[google-italiana] py-1.5'>
                    <span>
                      <p>{item.description}</p>
                    </span>
                  </p>
                </div>
              ))}
            </div>
            <hr className={`border-t-2  mt-5 my-4 ${transitioning ? 'fade-out' : fadeIn ? 'fade-in' : ''}`} />
          </div>

        ))}
      </div>
      <ButtonUp />
    </div>

  );
};

export default Menu;