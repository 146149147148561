import * as React from 'react';
import Box from '@mui/material/Box';
import BookingCard from './BookingDayCard';


export default function BookingGrid() {
  const bookings = [
    {
      "name": "Aaron Benitez",
      "email": "aaron@gmail.com",
      "shift": "19:00 - 20:00",
      "guests": 6,
      "telephone": "+34 123 456 789",
      "confirmed": true
    },
    {
      "name": "Maria Garcia",
      "email": "maria.garcia@example.com",
      "shift": "20:00 - 21:00",
      "guests": 4,
      "telephone": "+34 987 654 321",
      "confirmed": false
    },
    {
      "name": "Carlos Martinez",
      "email": "carlos.martinez@example.com",
      "shift": "18:00 - 19:00",
      "guests": 2,
      "telephone": "+34 654 321 987",
      "confirmed": true
    },
    {
      "name": "Laura Fernandez",
      "email": "laura.fernandez@example.com",
      "shift": "21:00 - 22:00",
      "guests": 5,
      "telephone": "+34 321 654 987",
      "confirmed": false
    },
    {
      "name": "David Lopez",
      "email": "david.lopez@example.com",
      "shift": "17:00 - 18:00",
      "guests": 3,
      "telephone": "+34 789 123 456",
      "confirmed": true
    },
    {
      "name": "Ana Sanchez",
      "email": "ana.sanchez@example.com",
      "shift": "22:00 - 23:00",
      "guests": 7,
      "telephone": "+34 456 789 123",
      "confirmed": false
    }
  ]
  return (
    <div class="h-auto pb-10 w-[100%]">
      <div class="p-6 flex-1 overflow-auto">
        <div class="grid gap-4">
          {bookings &&
            bookings.map(booking => 
              <BookingCard name={booking.name}
                email={booking.email}
                shift={booking.shift}
                telephone={booking.telephone}
                guests={booking.guests}
                confirmed={booking.confirmed} />
            )}
        </div>
      </div>
    </div>
  );
}