import React, { useState, useEffect} from 'react';
import './../styles/multistepform.css';


function Booking() {
    const [step, setStep] = useState(1)
    const [bookingType, setBookingType] = useState(null)
    const [date, setDate] = useState("")
    const [time, setTime] = useState("")
    const [guests, setGuests] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [eventDetails, setEventDetails] = useState("")
    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (bookingType) {
        setStep(2)
        }
    }, [bookingType])

    const handleBack = () => {
        if (step > 1) {
        setStep(step - 1)
        if (step === 2) {
            setBookingType(null)
        }
        }
    }

    const validateInputs = () => {
        const newErrors = {}

        if (!name) newErrors.name = "Se necesita un Nombre"
        if (!email) newErrors.email = "Introduzca su correo"
        else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = "Correo inválido"
        if (!phone) newErrors.phone = "Introduzca un teléfono"
        else if (!/^\d{9}$/.test(phone)) newErrors.phone = "El número de teléfono debe tener 9 dígitos"

        if (bookingType === "reservation") {
        if (!date) newErrors.date = "Introduzca la fecha"
        if (!time) newErrors.time = "Introduzca la hora"
        if (!guests) newErrors.guests = "Introduzca los comensales"
        } else if (bookingType === "event") {
        if (!eventDetails) newErrors.eventDetails = "Introduzca los detalles del evento"
        }

        setErrors(newErrors)
        return Object.keys(newErrors).length === 0
    }

    const handleNext = () => {
        if (step < 3 && validateInputs()) {
        setStep(step + 1)
        }
    }

    const handleSubmit = () => {
        if (validateInputs()) {
        console.log("Booking submitted:", { bookingType, date, time, guests, name, email, phone, eventDetails })
        setStep(1)
        setBookingType(null)
        setDate("")
        setTime("")
        setGuests("")
        setName("")
        setEmail("")
        setPhone("")
        setEventDetails("")
        setErrors({})
        }
    }

    const getAvailableTimes = () => {
        return ["18:00", "19:00", "20:00", "21:00"]
    }

    const totalSteps = 3


    return (
      <div className="booking-card sm:border sm:border-main-green sm:rounded-lg sm:shadow sm:shadow-lg sm:mt-[15%] lg:mt-[10%] sm:mx-auto mt-[22%]">
        <div className="booking-header">
          {step === 1 && (
            <>
            <h1 className="booking-title font-[google-italianno]">Haz tu reserva ahora</h1>
            <p className="booking-description font-[google-italianno]">Reserva una mesa o planea tu evento</p>
            </>
          )}
          <div className="progress-bar">
            <div
              className="progress"
              style={{ width: `${(step / totalSteps) * 100}%` }}
            />
          </div>
          <div className="step-indicator font-[google-italianno]">
            <span>Paso {step} de {totalSteps}</span>
          </div>
        </div>
        <div className="booking-content">
          {step === 1 && (
            <div className="booking-type">
              <button
                className={`booking-option ${bookingType === "reservation" ? "selected" : ""}`}
                onClick={() => setBookingType("reservation")}
              >
                <h3 class="font-[google-italianno]">Reserva</h3>
                <p></p>
              </button>
              <button
                className={`booking-option ${bookingType === "event" ? "selected" : ""}`}
                onClick={() => setBookingType("event")}
              >
                <h3 class="font-[google-italianno]">Evento</h3>
                <p class="font-[google-italianno]">Celebra tu evento con nosotros</p>
              </button>
            </div>
          )}
          {step === 2 && (
            <div className="booking-details">
              {bookingType === "reservation" && (
                <>
                  <div className="form-group">
                    <div class="flex">
                      <label class="font-[google-italianno]" htmlFor="date">Fecha</label>
                      {errors.date && <p className="error font-[google-italianno] font-[google-italianno] font-bold ml-5">*</p>}
                    </div>
                    <input
                      type="date"
                      id="date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      min={new Date().toISOString().split('T')[0]}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                  <div class="flex">
                    <label class="font-[google-italianno]" htmlFor="time">Hora</label>
                    {errors.time && <p className="error font-[google-italianno] font-[google-italianno] font-bold ml-5">*</p>}
                  </div>
                    <select
                      id="time"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                      className="form-control"
                    >
                      <option value="">Select time</option>
                      {getAvailableTimes().map((t) => (
                        <option key={t} value={t}>
                          {t}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <div class="flex">
                      <label class="font-[google-italianno]"  htmlFor="guests">Comensales</label>
                      {errors.guests && <p className="error font-[google-italianno] font-bold ml-5">*</p>}
                    </div>
                    <select
                      id="guests"
                      value={guests}
                      onChange={(e) => setGuests(e.target.value)}
                      className="form-control"
                    >
                      <option value="">Número de comesales</option>
                      {[1, 2, 3, 4, 5, 6, 7, 8].map((num) => (
                        <option key={num} value={num.toString()}>
                          {num}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}
              <div className="form-group">
                <div class="flex">
                  <label class="font-[google-italianno]"  htmlFor="name">Nombre</label>
                  {errors.name && <p className="error font-[google-italianno] font-bold ml-5">*</p>}
                </div>
                <input
                  id="name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <div class="flex">
                  <label class="font-[google-italianno]"  htmlFor="email">Correo</label>
                  {errors.email && <p className="error font-[google-italianno] font-bold ml-5">*</p>}
                </div>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <div class="flex">
                  <label class="font-[google-italianno]"  htmlFor="phone">Teléfono</label>
                  {errors.phone && <p className="error font-[google-italianno] font-bold ml-5">*</p>}
                </div>
                <input
                  id="phone"
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="form-control"
                />
              </div>
              {Object.keys(errors).length > 0 && <p className="error font-[google-italianno] mb-5 font-bold">Estos campos son obligatorios *</p>}
              {bookingType === "event" && (
                <div className="form-group">
                  <div class="flex">
                    <label class="font-[google-italianno]"  htmlFor="eventDetails">Detalles del evento</label>
                    {errors.eventDetails && <p className="error font-[google-italianno] font-bold ml-5">*</p>}
                  </div>
                  <textarea
                    id="eventDetails"
                    value={eventDetails}
                    onChange={(e) => setEventDetails(e.target.value)}
                    placeholder="Describe que esperas de tu evento"
                    className="form-control"
                  />
                </div>
              )}
            </div>
          )}
          {step === 3 && (
            <div className="booking-summary">
              <h3 class="font-[google-italianno]">Resumen de la reserva</h3>
              <p class="font-[google-italianno]">Tipo: {bookingType === "reservation" ? "Single Reservation" : "Event"}</p>
              {bookingType === "reservation" && (
                <>
                  <p class="font-[google-italianno]">Fecha: {date}</p>
                  <p class="font-[google-italianno]">Hora: {time}</p>
                  <p class="font-[google-italianno]">Invitados: {guests}</p>
                </>
              )}
              <p class="font-[google-italianno]">Nombre: {name}</p>
              <p class="font-[google-italianno]">Correo: {email}</p>
              <p class="font-[google-italianno]">Teléfono: {phone}</p>
              {bookingType === "event" && <p class="font-[google-italianno]">Detalles: {eventDetails}</p>}
            </div>
          )}
        </div>
        <div className="booking-footer">
          {step > 1 && (
            <button onClick={handleBack} className="btn btn-secondary font-[google-italianno]">
              Atrás
            </button>
          )}
          {step === 2 && (
            <button onClick={handleNext} className="btn btn-primary font-[google-italianno]">
              Siguiente
            </button>
          )}
          {step === 3 && (
            <button onClick={handleSubmit} className="btn btn-primary font-[google-italianno]">
              Confirmar Reserva
            </button>
          )}
        </div>
      </div>
    );
}

export default Booking;