import React from 'react';

function ButtonUp() {
  const [hideButton, setHideButton] = React.useState(true);

  React.useEffect(() => {
    const handleScroll = () => {
      if (document.documentElement.scrollTop < 600) {
        setHideButton(true);
      } else {
        setHideButton(false);
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <div id="button-up" onClick={handleScrollUp} className={`sticky bottom-2 right-2 p-1 transition-opacity ${hideButton ? 'opacity-0' : 'opacity-100'}`}>
      <button
        id="scroll-to-top"
        aria-label="Volver al inicio de la página"
        class="group flex size-9 cursor-default items-center justify-center rounded-lg border-2 border-primary 
                       text-primary backdrop-blur hover:scale-105 hover:border-accent motion-safe:transition "
      >
        <svg
          aria-label="Subir al inicio de la página"
          stroke-width="2"
          stroke="currentColor"
          viewBox="0 0 24 24"
          fill="none"
          class="h-6 w-6 -rotate-45 group-hover:-rotate-90 group-hover:text-accent motion-safe:transition"
          width="25px"
        >
          <path d="M14 5l7 7m0 0l-7 7m7-7H3" stroke-linejoin="round" stroke-linecap="round"></path>
        </svg>
      </button>
    </div>
  );
}

export default ButtonUp;