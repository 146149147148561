import * as React from 'react';

function BookingCard({ name, email, guests, telephone, shift, confirmed }) {
    const [isConfirmed, setisConfirmed] = React.useState(confirmed);
    const [isCancelled, setisCancelled] = React.useState(false);

    const cancelReservation = () => {
        setisCancelled('true');
    }
    const confirmReservation = () => {
        setisConfirmed('true');
    }
    return (
        <div className={`border-2 p-6 rounded-md ${isCancelled ? 'bg-gray' : ''} border-main-green shadow-lg`}>
            <div class="flex flex-col lg:flex-row justify-between">
                <div class="flex flex-row py-2 items-center">
                    <h2 class="text-sm lg:text-md font-bold mr-2 lg:mr-8">{shift}</h2>
                    <h2 class="text-sm lg:text-md w-28 lg:w-32 lg:mx-8">{name}</h2>
                    <h3 class="text-sm lg:text-md w-20 mr-2 lg:mr-16">{guests} Personas</h3>
                    <h3 class="text-sm lg:text-md w-48 lg:w-52 lg:mr-8">{email}</h3>
                    <h3 class="text-sm lg:text-md">{telephone}</h3>
                </div>
                <div class="flex flex-row pt-4 lg:pt-0 text-sm items-center ">
                    {isCancelled && <div class="px-2 py-2 lg:mx-2 border-2 border-gray-dark rounded-md">
                        <h3 class=" font-bold flex align-middle justify-center text-gray-dark">Cancelado</h3>
                    </div>}
                    {(isConfirmed && !isCancelled) && <div class="px-2 py-2 lg:mx-2 border-2 border-main-green rounded-md">
                        <h3 class=" font-bold flex align-middle justify-center text-main-green">Confirmado</h3>
                    </div>}
                    {(!isConfirmed && !isCancelled) && <button onClick={confirmReservation} class="border-black bg-main-green hover:brightness-110 border-2 font-bold text-white py-2 px-2 lg:mx-2 rounded-md">Confirmar</button>}
                    {(!isConfirmed && !isCancelled) && <button onClick={cancelReservation} class="border-black bg-red border-2 hover:brightness-110 font-bold text-white py-2 px-2 mx-2 rounded-md">Cancelar</button>}
                </div>
            </div>

        </div>
    );
}

export default BookingCard;