import React from 'react';

function MenuFilterButton({ title, onClick, active }) {
    return (
        <button
            onClick={onClick}
            className= {` ${active && 'bg-main-green text-[White]'}
                font-[bagiste] font-extrabold cursor-pointer
                hover:bg-main-green
                hover:text-[White]
                px-2 py-2.5 mx-2 rounded-lg
                transition-all duration-200 ease-in-out scale-100 opacity-90 hover:opacity-100`}
        >
            {title}
        </button>
    );
}

export default MenuFilterButton;