import * as React from 'react';
import plate1 from './../images/plate1.jpg';
import plate2 from './../images/plate2.jpg';
import plate3 from './../images/plate3.jpg';
import plate4 from './../images/plate4.jpg';
import plate5 from './../images/plate5.jpg';
import plate6 from './../images/plate6.jpg';

function Food (props) {
    return (
        <section class="bg-main-green text-main-gold w-full lg:w-auto">
            <div>
                <header>
                    <h1
                        class="text-center font-[Italianno] text-5xl lg:text-5xl py-4 lg:py-7"
                    >
                        Nuestros Platillos
                    </h1>
                </header>
            </div>
            <div class="flex flex-col grid grid-cols-2 md:grid-cols-3 gap-0">
                <div class="relative overflow-hidden">
                    <img src={plate1} alt="Plate1" class="w-full h-full"/>
                </div>
                <div class="relative overflow-hidden">
                    <img src={plate2} alt="Plate2" class="w-full h-full"/>
                </div>
                <div class="relative overflow-hidden">
                    <img src={plate3} alt="Plate3" class="w-full h-full"/>
                </div>
                <div class="relative overflow-hidden">
                    <img src={plate4} alt="Plate4" class="w-full h-full"/>
                </div>
                <div class="relative overflow-hidden">
                    <img src={plate5} alt="Plate5" class="w-full h-full"/>
                </div>
                <div class="relative overflow-hidden">
                    <img src={plate6} alt="Plate5" class="w-full h-full"/>
                </div>
            </div>

        </section>
    );
}


export default Food;