import { useState } from "react";
import { WeekPicker } from "./WeekPicker";
import BooksWeekCard from "./BooksWeekCard";
import BookingsDayGrid from "./BookingsGrid";

function BookingDashboard() {
    const [week, setWeek] = useState({ firstDay: "02-02-2022" });
    const [todaySelected, setTodaySelected] = useState(true);
    let [days, setDays] = useState([
        { day: "Lunes", number: 1, reservations: 3, guests: 12 },
        { day: "Martes", number: 2, reservations: 4, guests: 20 },
        { day: "Miércoles", number: 3, reservations: 8, guests: 42 },
        { day: "Jueves", number: 4, reservations: 10, guests: 40 },
        { day: "Viernes", number: 5, reservations: 12, guests: 48 },
        { day: "Sábado", number: 6, reservations: 16, guests: 54 },
        { day: "Domingo", number: 7, reservations: 20, guests: 68 },
    ])

    const convertDate = (date, nextDay) => {
        let dt = new Date(date);
        dt.setDate(dt.getDate() + nextDay);

        return `${dt.getDate()}.${dt.getMonth() + 1}.${dt.getFullYear()}`;
    }

    const clickToday = () => {
        setTodaySelected(true);
    }

    const clickWeek = () => {
        setTodaySelected(false);
    }

    const onChange = (week) => {
        setWeek(week);
        setTodaySelected(false);

        for (let i = 0; i < days.length; i++) {
            days[i].number = convertDate(week.firstDay, i).split(".")[0];
            if (days[i].number < 10) {
                days[i].number = "0" + days[i].number;
            }
        }
        setDays([...days]);
    };

    return (
        <>
            <div className={`min-h-[760px]`}>
                <div class="flex flex-col justify-between mt-20 bg-[#f3f0e2]">
                    <div class="flex flex-row justify-between px-16 pt-5 pb-10">
                        <h1 class="font-bold text-xl md:text-3xl mx-10">RESERVAS OSTERIA PASTAI</h1>
                        <div class="flex flex-row items-center justify-end border-2 border-main-green bg-[#595750a7] p-2">
                            <button onClick={clickToday}
                                className={`bg-[#f3f0e2] border-2 font-bold border-black rounded-full px-5 py-2 mx-5 ${todaySelected ? 'bg-main-green text-[#ffffff]' : ''}`}>
                                Today
                            </button>
                            {todaySelected &&
                                <button onClick={clickWeek} class="bg-[#f3f0e2] border-2 font-bold border-black rounded-full px-5 py-2 mx-5">
                                    Week
                                </button>
                            }
                            {!todaySelected && <WeekPicker onChange={onChange} />}

                        </div>
                    </div>
                    {todaySelected && (<div class="flex flex-row px-16 py-5">
                        <BookingsDayGrid />
                    </div>)}
                    {!todaySelected &&
                        <div class="grid grid-cols-2 md:grid-cols-3 gap-14 justify-center px-20 my-10">
                            {days.map((day) => (
                                <BooksWeekCard day={day.day} number={day.number} reservations={day.reservations} guests={day.guests} />
                            ))}
                        </div>
                    }
                </div>
            </div>
        </>

    );
}

export default BookingDashboard;