import * as React from 'react';
import plate7 from "./../images/plate7.jpg";
import Instagram from "./../images/INSTAGRAM.svg";
import Facebook from "./../images/Facebook.svg";
import Whatsapp from "./../images/whatsapp.svg";

function Contact(props) {
    return (
        <section class="flex flex-col lg:flex-row items-start bg-main-green">
            <article class="bg-main-green text-main-gold mb-5 w-full lg:w-auto">
                <header>
                        <h2
                            class="text-center font-[Hatton] text-3xl lg:text-5xl py-4 lg:py-7 m-5"
                        >
                            DISFRUTE DE COMIDA
                        </h2>
                        <h2
                            class="text-center font-[Hatton] font-semibold text-3xl lg:text-5xl py-4 lg:py-7 m-5"
                        >
                            VERDADERAMENTE ITALIANA
                        </h2>
                    </header>
                    <p class="text-center font-[google-italiana] px-4 lg:px-12 text-sm lg:text-lg">
                        Plaza Rancho Coco, Located behind Café Costello
                        <br /><br />
                        San Pedro Sula, Honduras
                        <br /><br/>
                        Email: osteriapasta@gmail.com
                        <br /><br/>
                        Teléfono: +504 8888-1910
                        <div class="mt-10 justify-between flex">
                            <a href="https://www.instagram.com/osteriapastai">
                                <img src={Instagram} class="h-10 w-10" alt="Logo Osteria" />
                            </a>
                            <a href="https://www.facebook.com/p/Osteria-Pastai-61552631633953">
                                <img src={Facebook} class="h-10 w-10" alt="Logo Osteria" />
                            </a>
                            <a href="https://api.whatsapp.com/send/?phone=50488881910&text&type=phone_number&app_absent=0">
                                <img src={Whatsapp} class="h-10 w-10" alt="Logo Osteria" />
                            </a>
                        </div>
                    </p>
                </article>
                <div class="w-full lg:w-[1600px]">
                    <img
                        class="object-cover object-center opacity-85 w-full lg:block hidden sm:h-[80vh]"
                        src={plate7}
                        alt="Imagen Sobre Nosotros"
                    />
                </div>
           
            </section>        
    );
}

export default Contact;